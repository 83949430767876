<template>
<div class="home" style='position: relative; float: top; height: calc(100vH - 120px); background-color: #eee'>
 
  MANUAL
  
  <div style='position: absolute; bottom: 0; text-align: right; width: 100%; padding-right: 20pt; font-size: 10pt; font-weight:bold;'>
  &copy; 2023 nextadsystem.com
  </div>
  
</div>
</template>
<script>
import {myName} from '../variables';
import { reloadMenubar } from '@/breadCrumb.js';
import Popup from'@/components/Popup.vue';
export const image = '/bg/background'+Math.round(1+10*Math.random())+'.JPG';

export default {
  name: 'home',
  components: {
    
  },
   data () {
    return {
     myName, image,
     popupData : {
          "header" : "My popup",
          "body" : "hello world",
          "display" : "none"
        },
    }
  },
  methods: {
      
  },

  created() {
      sessionStorage.ident=this.$route.params.ident;
      reloadMenubar();
  }
}
</script>
<style lang="scss" scoped>
  .hero {    
    text-align: center;
    background-image: url('/background2.JPG');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
  }
  .hero-body .title {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px 0 20px 0;
    font-size: 60px;
  }
  .info-body {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 20px 20px 20px 20px;
    background-color: coral;
    font-size: 60px;
  }
  .subtitle {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    font-size: 30px;
  }
  .button-block {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    //position: absolute;
    bottom: -150px;
    .button {
     // margin-right: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .welcome {
      width: 400px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-xl {
    font-size: 1.7rem;
  }
</style>